exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-iletisim-examples-js": () => import("./../../../src/pages/iletisim/examples.js" /* webpackChunkName: "component---src-pages-iletisim-examples-js" */),
  "component---src-pages-iletisim-file-upload-js": () => import("./../../../src/pages/iletisim/file-upload.js" /* webpackChunkName: "component---src-pages-iletisim-file-upload-js" */),
  "component---src-pages-iletisim-index-js": () => import("./../../../src/pages/iletisim/index.js" /* webpackChunkName: "component---src-pages-iletisim-index-js" */),
  "component---src-pages-iletisim-thanks-js": () => import("./../../../src/pages/iletisim/thanks.js" /* webpackChunkName: "component---src-pages-iletisim-thanks-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-davetiye-katalog-js": () => import("./../../../src/templates/davetiye-katalog.js" /* webpackChunkName: "component---src-templates-davetiye-katalog-js" */),
  "component---src-templates-hizmetlerimiz-js": () => import("./../../../src/templates/hizmetlerimiz.js" /* webpackChunkName: "component---src-templates-hizmetlerimiz-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

